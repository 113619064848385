.background {
  width: 100%;
  background-image: url("../../../public/login_background.jpeg");
  max-width: 100%;
  height: 1200px;
  width: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  max-width: 800px;
  min-width: 500px;
  width: 100%;
  padding-top: 100px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.tokenExpiredContainer {
  max-width: 400px;
  padding: 40px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #4c6213;
  background: #272727;
  margin-top: 100px;
  border-radius: 4px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: white;
  text-align: center;
}

.activationSuccessContainer {
  max-width: 400px;
  padding: 40px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #4c6213;
  background: #272727;
  margin-top: 100px;
  border-radius: 4px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: white;
  text-align: center;
}

.iconContainer {
  margin-bottom: 20px;
}

.formContainer {
  max-width: 400px;
  padding-bottom: 40px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #4c6213;
  background: #272727;
  margin-top: 50px;
  border-radius: 4px;
}

.activationText {
  color: #e5e5e5;
  margin-top: 27px;
  font-size: 14px;
}

.inputContainer {
  margin-top: 20px;
}

.buttonContainer {
  margin-top: 30px;
}

.inputText {
  width: 270px;
}

.iflowLogo {
  padding: 20px;
}

.iFlowLogoForm {
  width: 170px;
  margin-top: 22px;
  margin-left: auto;
  margin-right: auto;
}

.iFlowLogoFormImg {
  width: 100%;
}

.linkButton {
  width: 270px;
}

.forgotDataContainer {
  margin-top: 20px;
}

.forgotData {
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
}

.signUpContainer {
  margin-top: 40px;
  color: white;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.signUpWelcome {
  color: white;
  font-weight: bold;
  margin-bottom: 4px;
}

.signUpText {
  color: white;
  font-size: 13px;
}

.signUpLink {
  color: white;
}
